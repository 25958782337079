import React from "react"
import { graphql } from "gatsby"
import { Container } from "@theme-ui/components"
import Layout from "../components/layout"
import { getStoriesPath, getStoryCategoryPath } from "../utils/path"
import CategoriesList from "./categoriesList"
import BlogTitle from "../components/blogTitle"
import { HelmetDatoCms } from "gatsby-source-datocms"
import StoriesList from "./storiesList"
import FilterMetaTagDescription from "../utils/filterMetaTags"

const Stories = ({ data: { page, articles, articleCategories, site } }) => {
  const i18nPaths = site.locales
    .filter(locale => locale !== "he")
    .map(locale => {
      return {
        locale: locale,
        value: getStoriesPath(locale),
      }
    })

  return (
    <Layout
      locale={page.locale}
      i18nPaths={i18nPaths}
      theme="dark"
      hideMenu={true}
    >
      <HelmetDatoCms seo={FilterMetaTagDescription(page.seoMetaTags)}>
        <html lang={page.locale} />
      </HelmetDatoCms>
      <Container sx={{ mt: [8, "16rem"] }}>
        <BlogTitle page={page} />
        <CategoriesList
          categories={articleCategories.nodes}
          getRootPath={getStoriesPath}
          getCategoryPath={getStoryCategoryPath}
        />
        <StoriesList articles={articles.nodes} />
      </Container>
    </Layout>
  )
}

export default Stories

export const query = graphql`
  query StoriesQuery($id: String!, $locale: String!) {
    page: datoCmsStoriesPage(id: { eq: $id }) {
      ...StoriesDetails
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
    }
    articles: allDatoCmsStory(
      sort: { fields: position, order: ASC }
      filter: { slug: { ne: null }, locale: { eq: $locale } }
    ) {
      nodes {
        ...StoryDetails
        ...StoryAllSlugLocales
        ...StoryMeta
      }
    }
    articleCategories: allDatoCmsStoryCategory(
      sort: { fields: position, order: ASC }
      filter: { slug: { ne: null }, locale: { eq: $locale } }
    ) {
      nodes {
        ...StoryCategoryDetails
        ...StoryCategoryAllSlugLocales
      }
    }
    site: datoCmsSite {
      locales
    }
  }

  fragment StoriesDetails on DatoCmsStoriesPage {
    id
    locale
    title
    model {
      apiKey
    }
  }
`
